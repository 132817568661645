import React from 'react'
import '../Css/Comon.css'
import { Card, Row, Col, Container } from 'react-bootstrap'

export const MobileItems = () => {
  return (
    <Container>
        <Row>
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
            <Col md={3} className='pb-4'> 
                <Card>
                    <Card.Body className='itemsCard'>
                        <div className='itemPhotos'>
                            <img src="./photos/items/mobile1.png" alt="" className='img-fluid' />
                        </div>
                        <div className='itemDis'>
                                <div className='itemTitle'>
                                     KXD A09 Unlocked Android Phones
                                </div>
                               
                                <div className='itemAmm pt-2'> 
                                    Tk 12000
                                </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
 
           
        
        </Row>

    </Container>
  )
}
