import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import {Header} from './Components/Header/Header'
import { HeaderItems } from './Components/HeaderItems/HeaderItems';
import { Footer } from './Components/Footer/Footer';
import { MobileItems } from './Components/Items/Mobile/MobileItems';
import { Grocery } from './Components/Items/Grocery/Grocery';


const route = createBrowserRouter([
  {
    path: "/",
    element: <>
      <Header />
      <HeaderItems />
      <MobileItems />
      <Grocery />
      <Footer />
    </>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <RouterProvider router={route} />
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
