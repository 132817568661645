import React from "react";
import "./Header.css";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping } from '@fortawesome/free-solid-svg-icons'

export const Header = () => {
  return (
    <div className="bgDark">
      <Container>
        <Row className="headerMain py-3">
          <Col md={3}>
            <img src="./photos/logo.png" className="img-fluid" style={{width:'150px'}}/>
          </Col>
          <Col md={6}>
            <form className="searchBox">
                <input type="text" placeholder="Search items...." autoFocus />
                <button><img src="./photos/search.png" alt="" /></button>
            </form>
          </Col>
          <Col md={3} className="auth">
            <div className="authSection">
              <a className="login">Login</a>
              <a className="reg">Register</a>
            </div>
            <div className="cardItem">
              <div className="sCard">
                <FontAwesomeIcon icon={faCartShopping} style={{fontSize:'30px'}}/>
                <span>0</span>
              </div>
             
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
