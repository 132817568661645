import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.css'


export const Footer = () => {
  return (
    <div className='footerMain'>
        <Container fluid>
            <Row>
                <Col md={3}>
                    <img src="./photos/logo.png" alt="" className='footer-logo' />
                    <p> Any Question? Call us 9 AM- 10 PM</p>
                    <b>01767-181214</b>
                </Col>

            
            </Row>

        </Container>
    </div>
   

  )
}
