import React from 'react'
import './HeaderItems.css'
import { Row, Col, Container } from 'react-bootstrap'

export const HeaderItems = () => {
  return (
    <div className='h_item_section'>
        <Container fluid>
        <Row>
          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/img.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">PHARMACY</small>
              </div>
            </div>
          </Col>


          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/tom.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">HOUSEHOLD FESTIVAL</small>
              </div>
            </div>
          </Col>



          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/home.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">SPECIAL WEEKLY </small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/fasion.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">FURNITURE BIGDAYS</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/home.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">BUILDING MATERALS</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/tom.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">TOR & BABYS</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/home.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">HOME & LIVING</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/electronic.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">ELECTRONICS</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/food.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">FOOD & GROCERY</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/fasion.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">FASHION</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/bag.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">BAGS & TRAVELS</small>
              </div>
            </div>
          </Col>

          <Col md={1}>
            <div className="sellsCat" >
              <img className="img-fluid cardImg" src="./photos/items/food.png" alt="Card image cap" />
              <div className="card-body">
                <small className="card-text">PHARMACY</small>
              </div>
            </div>
          </Col>
            

        </Row>
        </Container>       
    </div>
  )
}
