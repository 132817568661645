import React from 'react'
import '../Css/Comon.css'
import { Container, Row, Col, Card } from 'react-bootstrap'

export const Grocery = () => {
  return (
    <Container>
        <Row>
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
            <Col md={3} className='pb-4'>
                <Card>
                    <Card.Body className='itemsCard'>
                            <div className='itemPhotos'>
                                <img src="./photos/items/54.jpeg" alt="" className='img-fluid' />
                            </div>
                            <div className='itemDis'>
                                    <div className='itemTitle'>
                                        PRAN Chinigura Premium Rice 5kg
                                    </div>
                                
                                    <div className='itemAmm pt-2'> 
                                        Tk 790
                                    </div>
                            </div>
                    </Card.Body>
                </Card>
            </Col>
            
        </Row>
    </Container>
  )
}
